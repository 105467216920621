.table {
  width: 100%;
  border-spacing: 0;

  &__top {
    &-tr {
      width: 100%;
      display: block;
      background-color: #f6f6f9;
      border-radius: 16px;
    }
    &-th {
      padding: 0 3px;
      line-height: 28px;
      color: #b2b3c3;
      font-size: 10px;
      font-weight: 600;
      text-align: left;

      &:first-child {
        padding-left: 10px;
      }
      &:last-child {
        padding-right: 10px;
      }
    }
  }
  &__row {
    width: 100%;
    display: block;
    margin-top: 1px;
    position: relative;
    border-radius: 10px;

    &.checked {
      background-color: #f6f6f9;
    }
  }
  &__checkbox {
    margin-right: 10px;
    position: relative;

    &-input {
      visibility: hidden;
      width: 0;
      height: 0;
      position: absolute;

      &:checked {
        & + .table__checkbox-block {
          background-color: #232323;
          border-color: #232323;
        }
      }
    }
    &-block {
      width: 10px;
      min-width: 10px;
      height: 10px;
      border-radius: 3px;
      border: 1px solid #b2b3c3;
      cursor: pointer;

      span {
        font-size: 5px;
        color: #fff;
      }
    }
  }
  &__td {
    padding: 0 3px;
    height: 1px;
    min-height: 52.8px;
    color: #232323;
    font-size: 12px;
    font-weight: 400;
    //line-height: 120%;
    overflow: hidden;

    &:first-child {
      padding-left: 0;
      border-radius: 10px 0 0 10px;

      .table__td-inner {
        padding-left: 10px;
      }
    }
    &:last-child {
      padding-right: 0;
      border-radius: 0 10px 10px 0;

      .table__td-inner {
        padding-right: 40px;
        border-radius: 0 10px 10px 0;
      }
    }
    &-inner {
      width: 100%;
      height: 100%;
      position: relative;
      padding: 14px 0;
      border-bottom: 1px solid #f0f0f7;
      margin-bottom: 2px;
    }
  }
  &__edit {
    position: absolute;
    top: calc(50% - 13px);
    right: 10px;
    width: 26px;
    height: 26px;
    background-color: #44a77d;
    cursor: pointer;

    &:hover {
      background-color: #4fb78b;
    }
    span {
      font-size: 14px;
      color: #fff;
    }
  }
  &__move {
    position: absolute;
    top: calc(50% - 13px);
    right: 10px;
    width: 26px;
    height: 26px;
    background-color: #f6f6f9;
    cursor: pointer;

    &:hover {
      background-color: #44a77d;

      span {
        color: #fff;
      }
    }
    span {
      font-size: 14px;
      color: #232323;
    }
  }
}

.reqs-table {
  table {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tr {
    display: flex;
    width: 100%;
  }

  th,
  td {
    flex: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .td-status {
    flex-grow: 1;
    width: unset !important;
  }
  .th-status {
    flex-grow: 1;
    width: unset !important;
    max-width: unset !important;
  }
}
