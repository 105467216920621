.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    overflow-y: auto;
    z-index: 1400;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
        border-radius: 20px;
        background-color: #FFF;
        box-shadow: 4px 4px 34px 0 rgba(35, 35, 35, 0.35);
        width: 670px;
        max-width: calc(100% - 20px);
        padding: 30px 40px 40px 40px;
        position: relative;
        margin: 20px 0;

        .modal-close {
            position: absolute;
            top: 32px;
            right: 40px;
            font-size: 20px;
            color: #232323;
            cursor: pointer;

            &:hover {
                color: #FE3434;
            }
        }
        .modal-title {
            display: block;
            width: 100%;
            padding-right: 25px;
            color: #232323;
            font-size: 20px;
            font-weight: 400;
            line-height: 120%;
            margin: 0 0 15px 0;
        }
        .modal-button {
            height: 52px;
            padding: 0 25px;
            border-radius: 26px;
            background-color: #44A77D;
            border: none;
            cursor: pointer;
            color: #FFF;
            font-size: 14px;
            font-weight: 600;
            margin-top: 30px;

            &:hover {
                background-color: #4FB78B;
            }
        }
    }
    .table-settings {
        width: 100%;

        &__item {
            width: calc(50% - 5px);
            padding: 10px 10px 10px 0;
            position: relative;

            &:first-child,
            &:nth-child(2) {
                border-top: 1px solid #F0F0F7;
            }
            border-bottom: 1px solid #F0F0F7;

            &-input {
                visibility: hidden;
                width: 0;
                height: 0;
                position: absolute;

                &:checked {

                    &+.table-settings__item-inner {

                        .table-settings {

                            &__item {

                                &-title {
                                    color: #232323;
                                }
                                &-switch {
                                    background-color: #44A77D;

                                    &:before {
                                        left: auto;
                                        right: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-inner {
                width: 100%;
                height: 100%;
            }
            &-title {
                color: #B2B3C3;
                font-size: 12px;
                font-weight: 600;
                line-height: 120%;
            }
            &-switch {
                margin-left: 10px;
                width: 26px;
                min-width: 26px;
                height: 14px;
                background-color: #B2B3C3;
                border-radius: 13px;
                position: relative;
                align-items: center;

                &:before {
                    content: "";
                    border-radius: 50%;
                    width: 10px;
                    height: 10px;
                    background-color: #FFF;
                    position: absolute;
                    transition: all .3s linear;
                    left: 2px;
                }
            }
        }
    }
}
