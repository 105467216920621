:root {
  // COLORS
  --input-border-color: #f0f0f7;
  --input-disabled-color: #f6f6f9;

  // HEIGHTS
  --input-height: 44px;

  // FONT-SIZE
  --input-font-size: 12px;
  --input-label-font-size: 10px;
}
