.page-top {
  width: 100%;
  border-radius: 14px;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px 10px 10px 20px;

  &--black {
    background-color: #232323;

    .page-top {
      &__title {
        color: #fff;
        font-size: 20px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.6px;
      }
      &__right {
        margin-left: 0;
      }
    }
  }
  &__title {
    color: #232323;
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.6px;
  }
  &__left {
    margin-right: 10px;

    &-title {
      color: #232323;
      font-size: 20px;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.6px;
    }
    &-settings {
      margin-left: 10px;
      font-size: 14px;
      color: #b2b3c3;
      cursor: pointer;

      &:hover {
        color: #232323;
      }
    }
  }
  &__right {
    margin-left: auto;
  }
  &__back {
    width: 24px;
    min-width: 24px;
    height: 24px;
    background-color: #313133;
    margin-right: 10px;
    cursor: pointer;

    span {
      font-size: 14px;
      color: #fff;
    }
  }
  &__status {
    height: 24px;
    padding: 0 10px;
    border-radius: 12px;
    color: #fff;
    white-space: nowrap;
    font-size: 10px;
    font-weight: 600;
    margin-right: 20px;
    margin-left: 10px;

    &.completed {
      background-color: #4687e8;
    }
    &.in-work,
    &.weekend {
      background-color: #ef9129;
    }
    &.client,
    &.at-client {
      background-color: #44a77d;
    }
    &.on-base {
      background-color: #ef9129;
    }
  }
  &__created {
    &-icon {
      width: 24px;
      height: 24px;
      background-color: #313133;

      span {
        font-size: 14px;
        color: #fff;
      }
    }
    &-date {
      white-space: nowrap;
      color: #fff;
      font-size: 10px;
      font-weight: 600;
      margin-left: 8px;
    }
    &-editing {
      color: #5e5e62;
      font-size: 10px;
      font-weight: 400;
      margin-left: 8px;
    }
  }
  &__filter {
    &-item {
      height: 24px;
      padding: 0 10px;
      border-radius: 12px;
      background-color: #f6f6f9;
      color: #b2b3c3;
      font-size: 10px;
      font-weight: 400;
      cursor: pointer;
      margin-right: 2px;

      &:hover {
        color: #232323;
      }
      &:last-child {
        margin-right: 0;
      }
      &.is-active {
        background-color: #232323;
        color: #fff;
        cursor: default;
      }
    }
  }
  &__calendar {
    margin-left: 20px;

    &-icon {
      width: 24px;
      height: 24px;
      background-color: #f6f6f9;
      margin-right: 8px;

      span {
        font-size: 14px;
        color: #232323;
      }
    }
    .data-picker {
      display: flex;
    }
    .data-picker_start .react-datepicker-wrapper {
      width: 42px;
    }
    .data-picker_start-axp .react-datepicker-wrapper {
      width: 70px;
    }
    .data-picker_end .react-datepicker-wrapper {
      width: 70px;
      padding-left: 4px;
      box-sizing: content-box;
    }
    .react-datepicker-wrapper {
      width: 55px;
      input {
        width: 100%;
        height: 100%;
        border: none;
        color: #232323;
        font-size: 10px;
        font-weight: 600;
        line-height: 120%;
        cursor: pointer;
        border-radius: 12px;
        transition: 0.3s linear all;

        &:hover {
          color: #44a77d;
        }
      }
    }
  }
  &__search {
    margin-left: 20px;
    height: 24px;
    width: 202px;

    .MuiTextField-root {
      height: 100%;
      width: 100%;

      .MuiInputBase-root {
        border-radius: 12px;
        height: 100%;
        padding-left: 5px;

        fieldset {
          border-color: #f0f0f7;
        }

        .MuiInputBase-input {
          font-size: 10px;
          line-height: 12px;
          color: #b2b3c3;
        }
      }
    }

    .css-i44wyl {
      height: 100%;
    }
    .css-x0qxq3 {
      height: 100%;

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid #dcdeeb !important;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #f0f0f7 !important;
        border-radius: 12px;
        transition: 0.3s linear all;
      }
      .css-1y3zh1 {
        height: 100%;
        padding: 0;
      }
      .css-ihdtdm {
        line-height: 0;

        .notranslate {
          color: #13171d;
          font-size: 10px;
          font-weight: 400;
          line-height: 120%;
        }
      }
    }
  }
  &__dispatcher {
    margin-left: 20px;
    position: relative;
    cursor: pointer;

    &:hover {
      .page-top {
        &__dispatcher {
          &-text {
            span {
              color: #44a77d;

              &.icon-angle-down {
                color: #44a77d;
                transform: rotate(180deg);
              }
            }
          }
        }
      }
      .dispatcher-menu {
        display: block;
        z-index: 100;
      }
    }
    &-icon {
      width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 12px;
      background-color: #f6f6f9;
      margin-right: 8px;

      span {
        font-size: 14px;
        color: #232323;
      }
    }
    &-text {
      color: #13171d;
      font-size: 10px;
      font-weight: 400;
      line-height: 120%;

      span {
        color: #232323;
        font-size: 10px;
        font-weight: 700;
        line-height: 120%;
        margin-left: 3px;

        &.icon-angle-down {
          font-weight: 400;
          font-size: 8px;
          margin-left: 5px;
        }
      }
    }
    .dispatcher-menu {
      position: absolute;
      right: 0;
      top: 100%;
      padding-top: 4px;
      width: 140px;
      display: none;

      &__inner {
        width: 100%;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 2px 2px 34px 0 rgba(19, 23, 29, 0.09);
        padding: 3px;
      }
      &__item {
        width: 100%;
        display: block;
        padding: 7px;
        border-radius: 8px;
        color: #13171d;
        font-size: 10px;
        font-weight: 600;

        &:hover {
          background-color: #f6f6f9;
        }
        &:last-child {
          &:hover {
            color: #fe3434;
          }
        }
      }
    }
  }
  &__setting {
    margin-left: 20px;
    position: relative;
    cursor: pointer;

    &:hover {
      .page-top {
        &__setting {
          &-text {
            span {
              color: #44a77d;

              &.icon-angle-down {
                color: #44a77d;
                transform: rotate(180deg);
              }
            }
          }
        }
      }
      .setting-menu {
        // display: block;
        z-index: 100;
      }
    }
    &-icon {
      width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 12px;
      background-color: #f6f6f9;
      margin-right: 4px;

      span {
        font-size: 14px;
        color: #232323;
      }
    }
    &-text {
      color: #13171d;
      font-size: 10px;
      font-weight: 400;
      line-height: 120%;

      span {
        color: #232323;
        font-size: 10px;
        font-weight: 700;
        line-height: 120%;
        margin-left: 3px;

        &.icon-angle-down {
          font-weight: 400;
          font-size: 8px;
          margin-left: 5px;
        }
      }
    }
    .setting-menu {
      position: absolute;
      right: 0;
      top: 100%;
      padding-top: 4px;
      width: 140px;
      display: none;

      &__inner {
        width: 100%;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 2px 2px 34px 0 rgba(19, 23, 29, 0.09);
        padding: 3px;
      }
      &__item {
        width: 100%;
        display: block;
        padding: 7px;
        border-radius: 8px;
        color: #13171d;
        font-size: 10px;
        font-weight: 600;

        &:hover {
          background-color: #f6f6f9;
        }
        &:last-child {
          &:hover {
            color: #fe3434;
          }
        }
      }
    }
  }
  &__button {
    height: 38px;
    padding: 0 15px;
    border-radius: 20px;
    background-color: #44a77d;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 20px;

    &:hover {
      background-color: #4fb78b;
    }
  }
}
