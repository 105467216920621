.application-page {
  width: 100%;

  .page-top {
    .truck-info {
      margin-left: 20px;

      &__icon {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: #313133;
        margin-right: 8px;

        span {
          font-size: 14px;
          color: #fff;
        }
      }
      &__text {
        color: #fff;
        font-size: 10px;
        font-weight: 400;
        line-height: 120%;
        white-space: nowrap;

        span {
          font-weight: 700;
        }
      }
    }
    .spent-fuel {
      margin-left: 20px;

      &__icon {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: #313133;
        margin-right: 8px;

        span {
          font-size: 14px;
          color: #fff;
        }
      }
      &__text {
        color: #fff;
        font-size: 10px;
        font-weight: 400;
        line-height: 120%;
        white-space: nowrap;

        span {
          font-weight: 700;
        }
      }
    }
  }
  &__top {
    width: 100%;
    padding: 10px 25px 20px 25px;

    &-item {
      margin-right: 20px;
      color: #b2b3c3;
      font-size: 12px;
      font-weight: 600;
      line-height: 120%;
      cursor: pointer;

      &:hover {
        color: #44a77d;

        span {
          color: #44a77d;
        }
      }
      &:first-child {
        color: #44a77d;

        span {
          color: #44a77d;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      span {
        font-size: 20px;
        color: #b2b3c3;
        margin-right: 6px;
      }
    }
  }
  &__content {
    width: calc(100% - 350px);
    border-radius: 14px;
    background: #fff;
    padding: 20px 20px 15px 20px;
  }
  &__history {
    width: 340px;
    margin-left: 10px;
    border-radius: 14px;
    background: #fff;
    padding: 0 20px 15px 20px;

    .history-list {
      width: 100%;
      border-top: 1px solid #f0f0f7;
      border-bottom: 1px solid #f0f0f7;
      max-height: 950px;
      overflow: auto;
      @supports (scrollbar-width: auto) {
        scrollbar-width: thin;
        scrollbar-color: hsl(0 0% 50%);
      }
      @supports selector(::-webkit-scrollbar) {
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: hsl(0 0% 50%);
        }
        &::-webkit-scrollbar-track {
          background: hsl(0 0% 50%);
        }
      }

      .history-item {
        width: 100%;
        position: relative;
        padding-left: 15px;

        &:before {
          content: "";
          position: absolute;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          left: 0;
          top: 22px;
        }
        &:after {
          content: "";
          position: absolute;
          width: 1px;
          height: 100%;
          border-radius: 50%;
          left: 3px;
          top: 22px;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
        &--green {
          &:before {
            background-color: #44a77d;
          }

          &:after {
            background-color: #44a77d;
          }
        }
        &--orange {
          &:before {
            background-color: #ef9129;
          }

          &:after {
            background-color: #ef9129;
          }
        }
        &--red {
          &:before {
            background-color: red;
          }

          &:after {
            background-color: red;
          }
        }
        &__inner {
          width: 100%;
          padding: 10px 0;
        }
        &__info {
          width: 100%;

          &-column {
            &:last-child {
              margin-left: 10px;
              align-items: flex-end;
              width: 105px;
            }
          }
          &-text {
            display: inline-block;
            white-space: nowrap;
            color: #b2b3c3;
            font-size: 10px;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 3px;
          }
          &-title {
            display: inline-block;
            color: #232323;
            font-size: 12px;
            font-weight: 700;
            line-height: 120%;
          }
          &-manager {
            margin-top: 2px;
            color: #232323;
            font-size: 10px;
            font-weight: 600;
            line-height: 120%;

            span {
              font-size: 12px;
              color: #232323;
              margin-left: 4px;
            }
          }
        }
        &__comment {
          display: block;
          width: 100%;
          color: #232323;
          font-size: 10px;
          font-weight: 400;
          line-height: 120%;
          margin-top: 5px;
        }
      }
    }
  }
}
