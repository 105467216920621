.companies-page {
    width: 100%;

    &__top {
        width: 100%;
        border-bottom: 1px solid #F0F0F7;
        margin-bottom: 24px;
    }
    &__filter {
        margin-right: 10px;
        overflow: hidden;
        overflow-x: auto;

        &-item {
            height: 44px;
            padding: 0 20px;
            color: #232323;
            font-size: 14px;
            font-weight: 400;
            border-right: 1px solid #F0F0F7;
            white-space: nowrap;
            cursor: pointer;

            &:hover {
                color: #44A77D;
            }
            &:first-child {
                border-radius: 14px 0 0 0;
            }
            &.is-active {
                background-color: #232323;
                color: #FFF;
                cursor: default;
            }
        }
    }
    &__actions {
        margin-left: auto;
        margin-right: 10px;
    }
    &__table {
        width: 100%;
        padding: 0 20px 20px 20px;
        overflow: hidden;
        overflow-x: auto;

        .table {
            min-width: 1200px;

            &__top {

                &-th {

                    &.th-id {
                        width: 100px;
                        min-width: 100px;
                        max-width: 100px;
                    }
                    &.th-type {
                        width: 90px;
                        min-width: 90px;
                        max-width: 90px;
                    }
                    &.th-name {
                        width: 100%;
                        min-width: 200px;
                    }
                    &.th-inn {
                        width: 100px;
                        min-width: 100px;
                        max-width: 100px;
                    }
                    &.th-director {
                        width: 250px;
                        min-width: 250px;
                        max-width: 250px;
                    }
                    &.th-contact {
                        width: 250px;
                        min-width: 250px;
                        max-width: 250px;
                    }
                    &.th-phone {
                        width: 120px;
                        min-width: 120px;
                        max-width: 120px;
                    }
                    &.th-email {
                        width: 230px;
                        min-width: 230px;
                        max-width: 230px;
                    }
                }
            }
            &__td {

                &.td-id {
                    width: 100px;
                    min-width: 100px;
                    max-width: 100px;
                }
                &.td-type {
                    width: 90px;
                    min-width: 90px;
                    max-width: 90px;

                    .td-type__block {
                        height: 24px;
                        max-width: 100%;
                        padding: 0 10px;
                        border-radius: 4px;
                        color: #FFF;
                        white-space: nowrap;
                        font-size: 10px;
                        font-weight: 600;

                        &.client {
                            background-color: #44A77D;
                        }
                        &.performer {
                            background-color: #9E1AEF;
                        }
                        &.polygon {
                            background-color: #EF9129;
                        }
                    }
                }
                &.td-name {
                    width: 100%;
                    min-width: 200px;
                    font-weight: 600;
                }
                &.td-inn {
                    width: 100px;
                    min-width: 100px;
                    max-width: 100px;
                }
                &.td-director {
                    width: 250px;
                    min-width: 250px;
                    max-width: 250px;
                }
                &.td-contact {
                    width: 250px;
                    min-width: 250px;
                    max-width: 250px;
                }
                &.td-phone {
                    width: 120px;
                    min-width: 120px;
                    max-width: 120px;

                    a {
                        color: #232323;
                    }
                }
                &.td-email {
                    width: 230px;
                    min-width: 230px;
                    max-width: 230px;

                    .table__td-inner {
                        padding-right: 40px;
                    }
                }
            }
        }
    }
}
