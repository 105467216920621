@font-face {
  font-family: 'clean-world';
  src: url('./../../fonts/clean-world.eot');
  src:
    url('./../../fonts/clean-world.eot') format('embedded-opentype'),
    url('./../../fonts/clean-world.ttf') format('truetype'),
    url('./../../fonts/clean-world.woff') format('woff'),
    url('./../../fonts/clean-world.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'clean-world' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-sms:before {
  content: '\e900';
}
.icon-refresh-circle:before {
  content: '\e901';
}
.icon-building:before {
  content: '\e902';
}
.icon-routing:before {
  content: '\e903';
}
.icon-document-text:before {
  content: '\e904';
}
.icon-check:before {
  content: '\e905';
}
.icon-tick-square:before {
  content: '\e906';
}
.icon-tick-square-checked:before {
  content: '\e907';
}
.icon-check-circle:before {
  content: '\e908';
}
.icon-check-circle-black:before {
  content: '\e909';
}
.icon-map:before {
  content: '\e90a';
}
.icon-edit:before {
  content: '\e90b';
}
.icon-drop:before {
  content: '\e90c';
}
.icon-question:before {
  content: '\e90d';
}
.icon-danger:before {
  content: '\e90e';
}
.icon-calendar:before {
  content: '\e90f';
  font-weight: 700;
}
.icon-calendar-b:before {
  content: '\e910';
}
.icon-calendar-bb:before {
  content: '\e91d';
}
.icon-delete:before {
  content: '\e911';
}
.icon-close-circle:before {
  content: '\e912';
}
.icon-close:before {
  content: '\e913';
}
.icon-close-b:before {
  content: '\e914';
}
.icon-settings:before {
  content: '\e915';
}
.icon-settings-b:before {
  content: '\e916';
}
.icon-setting:before {
  content: '\e917';
}
.icon-repeat:before {
  content: '\e918';
}
.icon-profile-black:before {
  content: '\e919';
}
.icon-profile:before {
  content: '\e91a';
  font-weight: 500;
}
.icon-search:before {
  content: '\e91b';
  font-weight: 500;
  color: #000;
}
.icon-list:before {
  content: '\e91c';
}
.icon-arrow-up:before {
  content: '\e91e';
}
.icon-arrow-down:before {
  content: '\e91f';
}
.icon-arrow-left:before {
  content: '\e920';
}
.icon-arrow-right:before {
  content: '\e921';
}
.icon-angle-up:before {
  content: '\e922';
}
.icon-angle-down:before {
  content: '\e923';
}
.icon-angle-left:before {
  content: '\e924';
}
.icon-angle-right:before {
  content: '\e925';
}
.icon-angle-up-b:before {
  content: '\e926';
}
.icon-angle-down-b:before {
  content: '\e927';
}
.icon-angle-left-b:before {
  content: '\e928';
}
.icon-angle-right-b:before {
  content: '\e929';
}
.icon-angle-up-bb:before {
  content: '\e92a';
}
.icon-angle-down-bb:before {
  content: '\e92b';
}
.icon-angle-left-bb:before {
  content: '\e92c';
}
.icon-angle-right-bb:before {
  content: '\e92d';
}
.icon-truck:before {
  content: '\e92e';
}
.icon-truck-black:before {
  content: '\e92f';
}
.icon-task-square:before {
  content: '\e930';
}
.icon-profile-2user:before {
  content: '\e931';
}
.icon-layer:before {
  content: '\e932';
}
.icon-graph:before {
  content: '\e933';
}
.icon-global:before {
  content: '\e934';
}
.icon-briefcase:before {
  content: '\e935';
}
.icon-money-remove:before {
  content: '\e936';
}
.icon-phone-circle-black:before {
  content: '\e937';
}
.icon-phone-circle:before {
  content: '\e938';
}
.icon-phone:before {
  content: '\e939';
}
.icon-telegram:before {
  content: '\e93a';
}
.icon-cube-black:before {
  content: '\e93b';
}
.icon-cube:before {
  content: '\e93c';
}
.icon-roof:before {
  content: '\e902';
}
.icon-contract:before {
  content: '\e904';
}
.icon-box:before {
  content: '\e93d';
}
.icon-unlock:before {
  content: '\e93e';
}
