html,
body {
  position: relative;
  line-height: normal;
  height: 100%;
  width: 100%;
  font:
    normal 14px Onest,
    Arial,
    Helvetica,
    sans-serif !important;
  background-color: #f6f6f9;
  margin: 0;
}
* {
  outline: none;
  box-sizing: border-box;
}
a {
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
  }
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.d-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.justify-around {
  justify-content: space-around;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.circle {
  border-radius: 50%;
}
.pr-10 {
  padding-right: 10px;
}
.cursor {
  cursor: pointer;
}
.circle-center {
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.block-center {
  align-items: center;
  justify-content: center;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.content-start {
  align-content: flex-start;
}
.content-center {
  align-content: center;
}
.content-end {
  align-content: flex-end;
}
.transition {
  -webkit-transition: 0.3s linear all;
  -moz-transition: 0.3s linear all;
  -o-transition: 0.3s linear all;
  transition: 0.3s linear all;
}
.no-scroll {
  overflow: hidden;
}
//  For inputs
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #b2b3c3;
  text-overflow: ellipsis;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  text-align: left;
  font-weight: normal;
}
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #b2b3c3;
  text-overflow: ellipsis;
  opacity: 1;
  -moz-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  text-align: left;
  font-weight: normal;
}
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  opacity: 0;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #b2b3c3;
  text-overflow: ellipsis;
  opacity: 1;
  -moz-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  text-align: left;
  font-weight: normal;
}
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  opacity: 0;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #b2b3c3;
  text-overflow: ellipsis;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  text-align: left;
  font-weight: normal;
}
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0;
}
//  For inputs (END)

.content {
  flex-grow: 1;
  padding-left: 72px;
}
.page-content {
  border-radius: 14px;
  background-color: #fff;
}
@media (min-width: 768px) and (max-width: 991px) {
  .content {
  }
}
@media (max-width: 767px) {
  .content {
  }
}
@media (max-width: 575px) {
  .content {
  }
}
