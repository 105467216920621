.aside {
  width: 62px !important;
  position: fixed;
  height: 100vh;
  z-index: 500;

  .aside-wrapper {
    width: 100%;
    border-radius: 14px;
    transition: 0.2s linear all;
    height: calc(100vh - 20px);
    overflow: hidden;
    overflow-y: auto;
    background-color: #fff;

    &:hover {
      width: 160px;

      .aside-logo {
        span {
          font-weight: 600;
          font-size: 20px;
          line-height: 22px;
          color: #333;
          opacity: 1;
          visibility: visible;
        }
      }
      .aside-menu {
        &__item-title {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .aside-logo {
    padding: 25px 0 30px 0;
    align-items: center;
    width: 100%;

    &__image {
      width: 62px;
      min-width: 62px;
      justify-content: center;

      img {
        width: 40px;
      }
    }
    &__logo-text {
      width: 58px;
    }
  }
  .aside-menu {
    list-style-type: none;
    padding-left: 0;
    margin: 0;

    &__item {
      margin-bottom: 12px;
      position: relative;
      padding: 0 0 0 9px !important;
      border-radius: 10px;

      &:hover {
        .aside-menu__item {
          &-title {
            color: #44a77d;
          }
        }
      }
      &.is-active {
        .aside-menu__item {
          &-icon {
            background-color: #44a77d;

            span {
              color: #fff;
            }
          }
          &-title {
            color: #44a77d;
          }
        }
      }
      &-icon {
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        transition: 0.2s linear all;
        position: relative;
        margin-left: 5px;
        background-color: #f6f6f9;
        margin-right: 10px;

        span {
          font-size: 16px;
          color: #232323;
        }
      }
      &-title {
        opacity: 0;
        visibility: hidden;
        transition: 0.2s linear all;
        white-space: nowrap;
        color: #13171d;
        font-size: 10px;
        font-weight: 600;
        line-height: 120%;
      }
    }
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  .aside {
  }
}
@media (max-width: 767px) {
  .aside {
  }
}
@media (max-width: 400px) {
  .aside {
  }
}
