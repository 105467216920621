.company-page {
    width: 100%;

    .page-top {

        &__block {
            color: #FFF;
            font-size: 10px;
            font-weight: 400;
            line-height: 120%;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
            &-icon {
                width: 24px;
                min-width: 24px;
                height: 24px;
                background-color: #313133;
                margin-right: 8px;

                span {
                    font-size: 14px;
                    color: #FFF;
                }
            }
            b {
                font-weight: 700;
            }
        }
    }
    &__content {
        width: calc(100% - 350px);
        border-radius: 14px;
        background: #FFF;
        padding: 0 20px 15px 20px;
    }
    &__aside {
        width: 340px;
        margin-left: 10px;
    }
    &__details {
        width: 100%;
        border-radius: 14px;
        background: #FFF;
        padding: 15px 20px;
        margin-bottom: 10px;

        .details-calendar {
            width: 100%;

            &__icon {
                width: 24px;
                height: 24px;
                background-color: #F6F6F9;
                margin-right: 8px;

                span {
                    font-size: 14px;
                    color: #232323;
                }
            }
            .react-datepicker-wrapper {
                width: 55px;
                height: 100%;

                input {
                    width: 100%;
                    height: 100%;
                    border: none;
                    color: #232323;
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 120%;
                    cursor: pointer;
                    border-radius: 12px;
                    transition: .3s linear all;

                    &:hover {
                        color: #44A77D;
                    }
                }
            }
        }
        .details-list {
            width: 100%;

            &__item {
                width: calc(50% - 10px);
                min-width: calc(50% - 10px);
                padding: 0 30px 4px 0;
                position: relative;
                margin-top: 20px;
                border-bottom: 1px solid #F0F0F7;

                &-title {
                    color: #232323;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 120%;
                    margin-bottom: 3px;
                }
                &-value {
                    color: #232323;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 120%;
                }
                &-icon {
                    position: absolute;
                    right: 0;
                    font-size: 24px;
                    color: #B2B3C3;
                }
            }
        }
        .details-finances {
            width: 100%;
            margin-top: 20px;

            &__item {
                width: calc(50% - 10px);
                min-width: calc(50% - 10px);
                padding: 0 0 4px 0;
                border-bottom: 1px solid #F0F0F7;

                &:last-child {

                    .details-finances__item-value {
                        color: #EE3636;
                    }
                }
                &-title {
                    color: #232323;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 120%;
                    margin-bottom: 3px;
                }
                &-value {
                    color: #44A77D;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 120%;
                }
            }
        }
    }
    &__history {
        width: 100%;
        border-radius: 14px;
        background: #FFF;

        &-tab {
            width: 50%;
            height: 44px;
            border-bottom: 1px solid #F0F0F7;
            background-color: #FFF;
            color: #232323;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;

            &:first-child {
                border-radius: 14px 0 0 0;
            }
            &:last-child {
                border-radius: 0 14px 0 0;
            }
            &.is-active {
                background-color: #232323;
                border-bottom: 1px solid #232323;
                color: #FFF;
                cursor: default;
            }
        }
        &-content {
            width: 100%;
            padding: 0 20px 15px 20px;

            .history-list {
                width: 100%;
                border-top: 1px solid #F0F0F7;
                border-bottom: 1px solid #F0F0F7;
                overflow: hidden;
                overflow-y: auto;
                max-height: 201.6px;

                .history-item {
                    width: 100%;
                    padding: 10px 0;
                    border-bottom: 1px solid #F0F0F7;

                    &:last-child {
                        border-bottom: none;
                    }
                    &__column {
                        width: 100%;

                        &:last-child {
                            margin-left: 10px;
                            align-items: flex-end;
                            width: auto;
                        }
                        &-text {
                            display: inline-block;
                            white-space: nowrap;
                            color: #B2B3C3;
                            font-size: 10px;
                            font-weight: 400;
                            line-height: 120%;
                            margin-bottom: 3px;

                            span {
                                color: #44A77D;
                                font-size: 10px;
                                font-weight: 600;
                            }
                        }
                        &-title {
                            display: inline-block;
                            color: #232323;
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 120%;
                        }
                        &-driver {
                            margin-top: 2px;
                            color: #232323;
                            font-size: 10px;
                            font-weight: 600;
                            line-height: 120%;
                        }
                    }
                }
            }
        }
        &-download {
            margin-top: 12px;
            color: #B2B3C3;
            font-size: 12px;
            font-weight: 600;
            line-height: 120%;
            cursor: pointer;

            &:hover {
                color: #44A77D;

                span {
                    color: #44A77D;
                }
            }
            span {
                font-size: 20px;
                margin-right: 6px;
            }
        }
    }
}
