.drivers-schedule__table {
  --font-size-header: 10px;
  --font-size-content: 12px;
  --color-gray-0: #b2b3c3;
  --cell-size: 28px;
  --border-color: #b2b3c3;
  --background-color: #f6f6f9;

  padding: 0 20px 20px;

  .schedule-table {
    width: 100%;

    .table-head__date,
    .table-head__week,
    .table-head__hours,
    .table-head__shift,
    .table-body__status {
      width: var(--cell-size);
      height: var(--cell-size);
    }

    .table-head__row-1,
    .table-head__row-2 {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .table-head__picker,
    .table-head__name,
    .table-head__week,
    .table-head__hours,
    .table-head__shift {
      font-size: var(--font-size-header);
      color: var(--color-gray-0);
    }

    .table-body__cell-employee,
    .table-head__row-1 {
      flex: 0 0 500px;
    }

    .table-head__row-2,
    .table-body__status-box {
      flex: 1;
    }

    .table-head {
      border-bottom: 1px solid var(--border-color);

      .table-head__rows {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 10px;

        .table-head__name {
          text-align: start;
          font-weight: 500;
        }

        .table-head__date {
          font-size: var(--font-size-content);
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--border-color);
          border-radius: 16px;
          background-color: var(--background-color);
        }
        .table-head__date.today-date {
          background-color: #000;
          color: #fff;
        }

        .table-head__shift-hours-box {
          display: flex;
          margin-left: 10px;
          gap: 20px;
        }

        .table-head__days-box,
        .table-head__weeks-box {
          display: flex;
          gap: 4px;
        }
      }
    }

    .table-body {
      .table-body__row {
        display: flex;
        justify-content: space-between;
        padding: 4px 10px;
        border-bottom: 1px solid #f0f0f7;

        .table-body__cell-employee {
          align-content: center;
        }

        .table-body__status-box {
          display: flex;

          .table-body__status-box_wrapper {
            display: flex;
            gap: 4px;
            justify-content: flex-end;
          }

          .table-body__select {
            border: 1px solid #f0f0f7;
            height: 100%;
            width: 100%;
            text-align: center;
            -webkit-appearance: none;
            -o-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 6px;
            cursor: pointer;
          }
          .table-body__select:hover {
            background-color: #4687e8;
            color: #fff;
            border: 1px solid #4687e8;
          }
          .table-body__select.is-passed:hover {
            background-color: #000;
            color: #fff;
          }
          .table-body__select::-ms-expand {
            display: none;
          }
        }
        .table-body__status.is-passed {
          background-color: var(--background-color);
          padding: 5px;
          border-radius: 7px;
        }
        .table-body__shift-hours-box {
          display: flex;
          align-items: center;
          gap: 24px;
          margin-left: 18px;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .table-body__row:hover {
        background-color: #232323;
        border-bottom: 1px solid transparent;
        border-radius: 10px;

        .table-body__cell-employee,
        .table-body__shift-hours-box {
          color: #fff;
        }
      }
    }

    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          border: none;
          border-radius: 10px;
          background-color: #000;
          color: #fff;
          cursor: pointer;
          text-align: center;
          height: 28px;
        }
      }
    }

    select.is-sick {
      background-color: red !important;
      border: 1px solid red !important;
      color: #fff;
    }
    select.is-passed {
      color: var(--color-gray-0);
    }
    select.is-ready {
      background-color: #aaffdb;
      border: 1px solid #aaffdb;
      color: #000;
    }
    select.is-weekend {
      background-color: #f6f6f9;
      border: 1px solid #f6f6f9;
      color: #000;
    }
    select.is-time-off {
      background-color: #232323;
      border: 1px solid #232323;
      color: #fff;
    }
    select.is-vertical-colored {
      border: 1px solid #232323 !important;
    }
  }
}
