$font-size: 10px;
$bg-color: #232323;
$select-height: 24px;
$select-border-radius: 6px;
$select-content-color: #fff;

.application-map__select,
.statistics-page__dynamic-filters,
.MuiBox-root {
  .MuiSelect-select {
    color: $select-content-color !important;
    padding-right: 18px !important;
    height: auto !important;
    min-width: 101px;
    box-sizing: content-box;
  }

  .MuiFormControl-root label {
    top: -4px;
    color: $select-content-color;
  }

  .MuiFormControl-root label.Mui-focused {
    top: 0px;
    color: #000;
    font-weight: 500;
  }

  .MuiFormControl-root label[data-shrink="true"] {
    color: #000;
    opacity: 0;
    top: 0px;
  }

  .MuiSelect-icon {
    color: $select-content-color !important;
  }
}
.select {
  background-color: $bg-color;
  border-radius: $select-border-radius !important;
  font-size: $font-size !important;
  height: $select-height !important;
  padding: 0;
}
