.application-map {
  width: 100%;

  &__top {
    width: 100%;
    border-bottom: 1px solid #f0f0f7;
    margin-bottom: 24px;
  }
  .page-content {
    background-color: transparent;
    height: calc(100vh - 88px);
  }
  &__tabs {
    width: 265px;
    min-width: 265px;
    border-radius: 14px;
    background-color: #fff;

    .tabs-top {
      width: 100%;

      &__item {
        width: 50%;
        height: 44px;
        border-bottom: 1px solid #f0f0f7;
        background-color: #fff;
        color: #232323;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;

        &:first-child {
          border-radius: 14px 0 0 0;
        }
        &:last-child {
          border-radius: 0 14px 0 0;
        }
        &.is-active {
          background-color: #232323;
          border-bottom: 1px solid #232323;
          color: #fff;
          cursor: default;
        }
      }
    }
    .tabs-content {
      width: 100%;
      padding: 0 10px 10px 10px;

      &__applications,
      &__containers {
        width: 100%;
      }
      &__block {
        width: 100%;
        margin-top: 24px;

        &-title {
          width: 100%;
          padding: 0 14px;
          color: #232323;
          font-size: 14px;
          font-weight: 700;
          line-height: 120%;
          margin-bottom: 11px;

          span {
            font-size: 16px;
            color: #232323;
            cursor: pointer;
            transform: rotate(0deg);
          }
          &.is-active {
            & + .block-list {
              display: block;
            }
            span {
              transform: rotate(180deg);
            }
          }
        }
        .block-list {
          width: 100%;
          max-height: 134.5px;
          overflow: hidden;
          overflow-y: auto;
          display: none;

          .list-item {
            width: 100%;
            padding: 5px 8px 5px 14px;
            border-radius: 7px;
            background-color: #f6f6f9;
            position: relative;
            margin-bottom: 4px;

            &:before {
              position: absolute;
              content: '';
              top: 5px;
              left: 5px;
              bottom: 5px;
              width: 3px;
              border-radius: 7px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            &.new {
              &:before {
                background-color: red;
              }
              .list-item__info-number {
                color: red;
              }
            }
            &.in-work {
              &:before {
                background-color: blue;
              }
              .list-item__info-number {
                color: blue;
              }
            }
            &.completed {
              &:before {
                background-color: yellowgreen;
              }
              .list-item__info-number {
                color: yellowgreen;
              }
            }
            &.appointed {
              &:before {
                background-color: pink;
              }
              .list-item__info-number {
                color: pink;
              }
            }
            &.expired {
              &:before {
                background-color: #ee3636;
              }
              .list-item__info-number {
                color: #ee3636;
              }
            }
            &.not-expired {
              &:before {
                background-color: #44a77d;
              }
              .list-item__info-number {
                color: #44a77d;
              }
            }
            &.on-base {
              &:before {
                background-color: #ef9129;
              }
              .list-item__info-number,
              .list-item__info-kind {
                color: #ef9129;
              }
            }
            &.at-client {
              &:before {
                background-color: #44a77d;
              }
              .list-item__info-number,
              .list-item__info-kind {
                color: #44a77d;
              }
            }
            &__info {
              flex-grow: 1;

              &-number {
                color: #44a77d;
                font-size: 10px;
                font-weight: 600;
                line-height: 120%;
                margin-bottom: 3px;
              }
              &-title,
              &-client {
                color: #232323;
                font-size: 12px;
                font-weight: 600;
                line-height: 120%;
              }
              &-client {
                padding-left: 10px;
              }
              &-kind {
                font-size: 10px;
              }
            }
            &__date {
              margin-left: 10px;

              &-date {
                color: #b2b3c3;
                font-size: 10px;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 3px;
              }
              &-action {
                font-size: 12px;
                color: #232323;
                cursor: grab;
              }
            }
          }
        }
      }
    }
  }
  &__map {
    flex-grow: 1;
    margin: 0 10px;
    border-radius: 14px;
    background: linear-gradient(0deg, #f6f6f9 0%, #f6f6f9 100%);
    overflow: hidden;
    position: relative;

    div {
      width: 100% !important;
      height: 100% !important;

      .ymaps-2-1-79-map {
        width: 100% !important;
        height: 100% !important;
      }
      [class*='ymaps-2-1'][class*='-ground-pane'] {
        filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
        -webkit-filter: grayscale(100%);
      }
    }
    .application-map__select {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      gap: 8px;
      width: 210px !important;
      height: 24px !important;
    }
  }
  &__aside {
    width: 265px;
    min-width: 265px;

    .aside-details {
      width: 100%;
      border-radius: 14px;
      background-color: #fff;
      padding: 10px;

      &__filter {
        &-item {
          height: 24px;
          padding: 0 7px;
          border-radius: 12px;
          background-color: #f6f6f9;
          color: #b2b3c3;
          font-size: 10px;
          font-weight: 400;
          cursor: pointer;
          margin-right: 1px;
          text-align: center;
          width: 100%;
          justify-content: center;

          &:hover {
            color: #232323;
          }
          &:last-child {
            margin-right: 0;
          }
          &.is-active {
            background-color: #232323;
            color: #fff;
            cursor: default;
          }
        }
      }
      .details-list {
        width: 100%;
        padding: 0 14px;
        margin-bottom: 10px;

        &__item {
          width: calc(50% - 3px);
          padding: 18px 0 5px 0;
          border-bottom: 1px solid #f0f0f7;

          &-icon {
            width: 38px;
            margin-bottom: 3px;
          }
          &-title {
            color: #232323;
            font-size: 10px;
            font-weight: 400;
            line-height: 120%;
          }
          &-value {
            color: #232323;
            font-size: 26px;
            font-weight: 700;
            line-height: 120%;
          }
        }
      }
    }
    .aside-cars {
      width: 100%;
      border-radius: 14px;
      background-color: #fff;
      padding: 10px;
      margin-top: 10px;

      &__title {
        color: #232323;
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
        padding: 0 14px;
        margin-bottom: 15px;
        display: block;
      }
      .cars-list {
        width: 100%;
        max-height: 270px;
        overflow: hidden;
        overflow-y: auto;

        .list-item {
          width: 100%;
          padding: 5px 8px 5px 14px;
          border-radius: 7px;
          background-color: #f6f6f9;
          position: relative;
          margin-bottom: 4px;

          &:before {
            position: absolute;
            content: '';
            top: 5px;
            left: 5px;
            bottom: 5px;
            width: 3px;
            border-radius: 7px;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &.green {
            &:before {
              background-color: #44a77d;
            }
            .list-item__info-title {
              color: #44a77d;
            }
          }
          &.orange {
            &:before {
              background-color: #ef9129;
            }
            .list-item__info-title {
              color: #ef9129;
            }
          }
          &.gray {
            &:before {
              background-color: #c8c8c8;
            }
            .list-item__info-title {
              color: #c8c8c8;
            }
          }
          &__info {
            flex-grow: 1;

            &-title {
              color: #44a77d;
              font-size: 10px;
              font-weight: 600;
              line-height: 120%;
              margin-bottom: 3px;
            }
            &-number {
              color: #232323;
              font-size: 12px;
              font-weight: 600;
              line-height: 120%;
            }
          }
          &__route {
            margin-left: 10px;

            &-text {
              color: #b2b3c3;
              font-size: 10px;
              font-weight: 400;
              line-height: 120%;
              margin-bottom: 3px;
            }
            &-info {
              color: #232323;
              font-size: 10px;
              font-weight: 700;
              line-height: 120%;

              span {
                margin-left: 4px;
                font-size: 12px;
                color: #232323;
              }
            }
          }
        }
      }
    }
  }
}
