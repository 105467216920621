.form {
  width: 100%;

  &__title {
    display: block;
    width: 100%;
    color: #232323;
    font-size: 18px;
    font-weight: 400;
    line-height: 120%;
    margin: 15px 0;
  }
  .form-fields {
    width: calc(100% + 10px);
    margin: 0 -5px;
    justify-content: flex-start !important;

    .form-field {
      width: 20%;
      padding: 0 5px;
      margin-bottom: 10px;

      &__input {
        width: 100%;
      }
      &__select {
        width: 100%;
        height: 44px;
        border-radius: 10px;
      }
      .css-1ald77x {
        color: #13171d;
        font-family: Onest, sans-serif !important;
        font-size: 10px;
        font-weight: 400;
        line-height: 120%;
        transform: translate(10px, -6px) scale(1);
      }
      .css-1x5jdmq {
        height: 44px;
        padding: 0 10px;
      }
      .css-1v4ccyo {
        border-radius: 10px;

        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: #dcdeeb;
          }
        }
        .MuiOutlinedInput-notchedOutline {
          transition: 0.3s linear all;
        }
      }
      .css-p0rm37 {
        transform: translate(10px, 14px) scale(1);
        color: #b2b3c3;
        font-family: Onest, sans-serif !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;
      }
      .css-segi59 {
        height: 44px;
        border-radius: 10px;
      }
      .css-14lo706 > span {
        color: #13171d;
        font-family: Onest, sans-serif !important;
        font-size: 10px;
        font-weight: 400;
        line-height: 120%;
      }
      .css-igs3ac {
        border: 1px solid #b1b1b1;
      }
      .css-fvipm8 {
        .css-qiwgdb {
          padding: 16.5px 10px;
        }
        .css-igs3ac {
          padding: 0 5px;
        }
        .css-yjsfm1 {
          overflow: visible;
          max-width: none;

          & > span {
            opacity: 1;
            display: block;
            margin-top: -1px;
            color: #13171d;
            font-family: Onest, sans-serif !important;
            font-size: 10px;
            font-weight: 400;
            line-height: 120%;
          }
        }
        .css-14lo706 > span {
          opacity: 1;
          display: block;
          margin-top: -1px;
        }
      }
      &.address {
        display: flex;
        width: 40%;

        .react-dadata {
          position: relative;
          margin-right: 10px;
          width: calc(50% - 5px);
          min-width: calc(50% - 5px);

          &__input {
            height: 44px;
            width: 100%;
            padding: 0 10px;
            border-radius: 10px;
            border: 1px solid #f0f0f7;
            transition: 0.3s linear all;
            color: #13171d;
            font-family: Onest, sans-serif !important;
            font-size: 10px;
            font-weight: 400;
            line-height: 120%;

            &:hover {
              border-color: #dcdeeb;
            }
          }
          &__suggestions {
            position: absolute;
            left: 0;
            top: calc(100% - 10px);
            width: 100%;
            box-shadow:
              rgba(0, 0, 0, 0.2) 0 2px 2px -3px,
              rgba(0, 0, 0, 0.08) 0 3px 5px 1px,
              rgba(0, 0, 0, 0.04) 0 3px 5px 2px;
            border-radius: 10px;
            border: 1px solid #dcdeeb;
            background: #fff;
            padding: 8px 4px;
            z-index: 10;
          }
          &__suggestion {
            width: 100%;
            border: 0;
            border-radius: 6px;
            padding: 5px 10px;
            color: #13171d;
            font-family: Onest, sans-serif !important;
            font-size: 10px;
            font-weight: 600;
            line-height: 120%;
            transition: 0.3s linear all;
            margin: 1px 0;
            text-align: left;
            background-color: #fff;
            cursor: pointer;

            &:hover {
              background-color: #f6f6f9;
            }
            mark {
              background-color: transparent;
            }
          }
        }
      }
      .css-f7t77y .MuiOutlinedInput-root .MuiAutocomplete-input {
        padding: 7.5px 4px 7.5px 0;
      }
      .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-f7t77y .MuiOutlinedInput-root {
        padding-right: 25px;
      }
    }
  }
  &__row {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    background-color: #f6f6f9;
    margin-bottom: 10px;

    &-inner {
      margin: 0 -5px;
      width: calc(100% + 10px);
    }
    &-field {
      flex-grow: 1;
      padding: 0 5px;

      .address_suggestions {
        height: 54px;
        max-width: 340px;
        .react-dadata__container,
        div,
        .react-dadata__input {
          height: 100%;
        }
        .react-dadata__input {
          background-color: unset;
        }
      }

      .css-1ald77x {
        color: #13171d !important;
        font-family: Onest, sans-serif !important;
        font-size: 10px;
        font-weight: 400;
        line-height: 120%;
        transform: translate(10px, -6px) scale(1);
      }
      .css-1x5jdmq {
        height: 44px;
        padding: 0 10px;
      }
      .css-1v4ccyo {
        border-radius: 10px;
        background-color: #fff;

        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: #dcdeeb;
          }
        }
        .MuiOutlinedInput-notchedOutline {
          transition: 0.3s linear all;
        }
      }
      .css-p0rm37 {
        transform: translate(10px, 14px) scale(1);
        color: #b2b3c3;
        font-family: Onest, sans-serif !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;
      }
      .css-segi59 {
        height: 44px;
        border-radius: 10px;
      }
      .css-14lo706 > span {
        color: #13171d;
        font-family: Onest, sans-serif !important;
        font-size: 10px;
        font-weight: 400;
        line-height: 120%;
      }
      .css-igs3ac {
        border: 1px solid #f0f0f7;
      }
      .css-1v4ccyo {
      }
      .css-fvipm8 {
        .css-qiwgdb {
          padding: 16.5px 10px;
        }
        .css-igs3ac {
          padding: 0 5px;
        }
        .css-yjsfm1 {
          overflow: visible;
          max-width: none;

          & > span {
            opacity: 1;
            display: block;
            margin-top: -1px;
            color: #13171d;
            font-family: Onest, sans-serif !important;
            font-size: 10px;
            font-weight: 400;
            line-height: 120%;
          }
        }
        .css-14lo706 > span {
          opacity: 1;
          display: block;
          margin-top: -1px;
        }
      }
    }
    &-input {
      width: 100%;
    }
    &-action {
      width: 30px;
      min-width: 30px;
      height: 44px;
      border-radius: 10px;
      background-color: #eeeef3;
      cursor: pointer;

      &:hover {
        .icon-delete {
          color: #fe3434;
        }
        .icon-check-circle {
          color: #44a77d;
        }
      }
      span {
        font-size: 14px;
        color: #b2b3c3;
      }
    }
  }
  &__add {
    height: 24px;
    padding: 0 15px;
    border-radius: 12px;
    border: 1px solid #44a77d;
    color: #44a77d;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      background-color: #44a77d;
      color: #fff;
    }
  }
  &__buttons {
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #f0f0f7;

    &-btn {
      height: 52px;
      padding: 0 30px;
      border-radius: 26px;
      font-size: 14px;
      font-weight: 600;
      margin-right: 10px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
      &--primary {
        border: 1px solid #44a77d;
        background-color: #44a77d;
        color: #fff;

        &:hover {
          background-color: #4fb78b;
        }
      }
      &--cancel {
        border: 1px solid #ededf4;
        color: #b2b3c3;

        &:hover {
          border: 1px solid #dbdce3;
          color: #9697ae;
        }
      }
    }
  }
}
.css-1sucic7 {
  .css-pwxzbm {
    box-shadow:
      rgba(0, 0, 0, 0.2) 0 2px 2px -3px,
      rgba(0, 0, 0, 0.08) 0 3px 5px 1px,
      rgba(0, 0, 0, 0.04) 0 3px 5px 2px;
    border-radius: 10px;
    border: 1px solid #dcdeeb;
    background: #fff;

    .css-r8u8y9 {
      padding: 8px 4px;

      .css-1km1ehz {
        border-radius: 6px;
        padding: 5px 10px;
        color: #13171d;
        font-family: Onest, sans-serif !important;
        font-size: 10px;
        font-weight: 600;
        line-height: 120%;
        transition: 0.3s linear all;
        margin: 1px 0;

        &:hover {
          background-color: #f6f6f9;
        }
      }
    }
  }
}
.css-1mtsuo7 {
  ul {
    padding: 8px 4px;

    li {
      border-radius: 6px;
      padding: 5px 10px;
      color: #13171d;
      font-family: Onest, sans-serif !important;
      font-size: 10px;
      font-weight: 600;
      line-height: 120%;
      transition: 0.3s linear all;
      margin: 1px 0;

      &:hover {
        background-color: #f6f6f9;
      }
      &.Mui-selected {
        background-color: #f6f6f9;
      }
    }
  }
}
