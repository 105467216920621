.containers-page {
  width: 100%;

  &__top {
    width: 100%;
    border-bottom: 1px solid #f0f0f7;
    margin-bottom: 24px;
  }
  &__filter {
    margin-right: 10px;
    overflow: hidden;
    overflow-x: auto;

    &-item {
      height: 44px;
      padding: 0 20px;
      color: #232323;
      font-size: 14px;
      font-weight: 400;
      border-right: 1px solid #f0f0f7;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        color: #44a77d;
      }
      &:first-child {
        border-radius: 14px 0 0 0;
      }
      &.is-active {
        background-color: #232323;
        color: #fff;
        cursor: default;
      }
    }
  }
  &__actions {
    margin-left: auto;
    margin-right: 10px;
  }
  &__table {
    width: 100%;
    padding: 0 20px 20px 20px;
    overflow: hidden;
    overflow-x: auto;

    .table {
      min-width: 1200px;

      &__top {
        &-th {
          &.th-id {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
          }
          &.th-status {
            width: 80px;
            min-width: 80px;
            max-width: 80px;
          }
          &.th-type {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
          }
          &.th-number {
            width: 90px;
            min-width: 90px;
            max-width: 90px;
          }
          &.th-address {
            width: 100%;
            min-width: 200px;
          }
          &.th-date {
            width: 80px;
            min-width: 100px;
            max-width: 80px;
          }
          &.th-phone {
            width: 120px;
            min-width: 120px;
            max-width: 120px;
          }
          &.th-order {
            width: 250px;
            min-width: 250px;
            max-width: 250px;
          }
          &.th-actions {
            width: 200px;
            min-width: 200px;
            max-width: 200px;
          }
        }
      }
      &__td {
        &.td-id {
          width: 100px;
          min-width: 100px;
          max-width: 100px;
        }
        &.td-status {
          width: 80px;
          min-width: 80px;
          max-width: 80px;

          .td-status__block {
            height: 24px;
            max-width: 100%;
            padding: 0 10px;
            border-radius: 4px;
            color: #fff;
            white-space: nowrap;
            font-size: 10px;
            font-weight: 600;

            &.at-client {
              background-color: #44a77d;
            }
            &.on-base {
              background-color: #ef9129;
            }
          }
        }
        &.td-type {
          width: 100px;
          min-width: 100px;
          max-width: 100px;
          font-weight: 600;
        }
        &.td-number {
          width: 90px;
          min-width: 90px;
          max-width: 90px;
        }
        &.td-address {
          width: 100%;
          min-width: 200px;
        }
        &.td-date {
          width: 80px;
          min-width: 80px;
          max-width: 80px;
        }
        &.td-phone {
          width: 120px;
          min-width: 120px;
          max-width: 120px;
        }
        &.td-order {
          width: 250px;
          min-width: 250px;
          max-width: 250px;
        }
        &.td-actions {
          width: 200px;
          min-width: 200px;
          max-width: 200px;

          .td-actions__block {
            width: 100%;
            color: #44a77d;
            font-size: 12px;
            font-weight: 600;
            line-height: 120%;
            cursor: pointer;

            &:hover {
              color: #4fb78b;

              span {
                color: #4fb78b;
              }
            }
            span {
              font-size: 20px;
              color: #44a77d;
              margin-right: 6px;
            }
          }
          .table__td-inner {
            padding-right: 40px;
          }
        }
      }
    }
  }
}
