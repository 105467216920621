.statistics-page {
  width: 100%;

  .page-content {
    background-color: transparent;
  }
  &__top {
    width: calc(100% + 10px);
    margin: 0 -5px;
  }
  &__details {
    width: 40%;
    min-width: 40%;
    padding: 0 5px;

    &-inner {
      width: 100%;
    }
  }
  &__detail {
    width: calc(50% - 5px);
    border-radius: 14px;
    background-color: #fff;
    padding: 20px 15px 20px 20px;
    margin-bottom: 10px;

    &-title {
      width: 100%;
      color: #232323;
      font-size: 14px;
      font-weight: 400;
      line-height: 110%;

      &--small {
        font-size: 12px;
      }
    }
    &-icon {
      margin-left: 10px;
      width: 40px;
      min-width: 40px;
      height: 40px;
      background-color: #f6f6f9;

      span {
        font-size: 20px;
        color: #44a77d;
      }
    }
    &-text {
      display: block;
      font-size: 12px;
      font-weight: 400;
      line-height: 120%;
      margin-top: 25px;

      &--green {
        color: #44a77d;
      }
      &--red {
        color: #da3737;
      }
    }
    &-value {
      display: block;
      color: #232323;
      font-size: 22px;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: -0.66px;
      margin-top: 3px;

      span {
        color: #b2b3c3;
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.48px;
      }
    }
    &-column {
      flex-grow: 1;
    }
  }
  &__dynamic {
    width: 60%;
    min-width: 60%;
    padding: 0 5px;
    margin-bottom: 10px;

    &-inner {
      width: 100%;
      height: 100%;
      border-radius: 14px;
      background-color: #fff;
      padding: 20px 20px 10px 20px;
    }
    &-title {
      width: 100%;
      color: #232323;
      font-size: 14px;
      font-weight: 400;
      line-height: 110%;
      margin-bottom: 15px;
    }
    &-filters {
      margin-left: auto;
      display: flex;
      gap: 8px;
    }
    &-filter {
      margin-left: 6px;
    }
    .chart {
      width: 100%;
      flex-grow: 1;
      overflow: hidden;
      overflow-x: auto;
      padding-bottom: 10px;

      .chart-item {
        flex-grow: 1;
        max-width: 20%;
        min-width: 80px;
        padding: 0 2.5px;

        &__top {
          width: 100%;
          flex-grow: 1;
          border-radius: 10px;
          background-color: #f6f6f9;
          justify-content: center;
          position: relative;
          overflow: hidden;

          &-orders {
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: #7ddcb4;
          }
        }
        &__bottom {
          width: 100%;
          flex-direction: column;
          align-items: center;

          &-month {
            color: #b2b3c3;
            font-size: 10px;
            font-weight: 500;
            line-height: 120%;
            display: inline-block;
            margin-top: 5px;
          }
          &-price {
            display: inline-block;
            margin-top: 4px;
            color: #232323;
            font-size: 14px;
            font-weight: 700;
            line-height: 110%;
          }
          &-orders {
            width: 100%;
            height: 20px;
            color: #44a77d;
            font-size: 10px;
            font-weight: 500;
            margin-top: 5px;
            border-radius: 6px;
            background-color: #f6f6f9;
          }
        }
      }
    }
  }
  &__tables {
    width: calc(100% + 10px);
    margin: 0 -5px;
  }
  &__table {
    padding: 0 5px;
    margin-top: 20px;
    overflow: hidden;
    overflow-x: auto;

    &-title {
      width: 100%;
      color: #232323;
      font-size: 18px;
      font-weight: 400;
      line-height: 120%;
      margin-bottom: 15px;
    }
    .table {
      &__top {
        &-tr {
          background-color: #fff;
          display: flex;
        }
      }
      &__td {
        &:last-child {
          .table__td-inner {
            padding-right: 10px;
          }
        }
      }
    }
    &.debtors-table {
      width: 40%;
      min-width: 40%;

      .table {
        min-width: 500px;

        &__top {
          &-th {
            &.th-id {
              width: 50px;
              min-width: 50px;
              max-width: 50px;
            }
            &.th-name {
              width: 100%;
              min-width: 160px;
            }
            &.th-inn {
              width: 100px;
              min-width: 100px;
              max-width: 100px;
            }
            &.th-amount {
              width: 100px;
              min-width: 100px;
              max-width: 100px;
              display: flex;
              gap: 10px;
              align-items: center;

              .sorted_up {
                transform: rotate(180deg);
              }
            }
          }
        }
        &__td {
          &.td-id {
            width: 50px;
            min-width: 50px;
            max-width: 50px;
          }
          &.td-name {
            width: 100%;
            min-width: 160px;
            font-weight: 600;
          }
          &.td-inn {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
          }
          &.td-amount {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
            font-weight: 600;
          }
        }
      }
    }
    &.containers-table {
      width: 32%;
      min-width: 32%;

      .table {
        min-width: 400px;

        &__top {
          &-th {
            &.th-status {
              width: 100px;
              min-width: 100px;
              max-width: 100px;
            }
            &.th-type {
              width: 100%;
              min-width: 110px;
            }
            &.th-number {
              width: 110px;
              min-width: 110px;
              max-width: 110px;
            }
            &.th-applications {
              width: 110px;
              min-width: 110px;
              max-width: 110px;
              display: flex;
              gap: 10px;
              align-items: center;

              .sorted_up {
                transform: rotate(180deg);
              }
            }
          }
        }
        &__td {
          &.td-status {
            width: 100px;
            min-width: 100px;
            max-width: 100px;

            .td-status__block {
              height: 24px;
              max-width: 100%;
              padding: 0 10px;
              border-radius: 4px;
              color: #fff;
              white-space: nowrap;
              font-size: 10px;
              font-weight: 600;

              &.at-client {
                background-color: #44a77d;
              }
              &.on-base {
                background-color: #ef9129;
              }
            }
          }
          &.td-type {
            width: 100%;
            min-width: 110px;
          }
          &.td-number {
            width: 110px;
            min-width: 110px;
            max-width: 110px;
          }
          &.td-applications {
            width: 110px;
            min-width: 110px;
            max-width: 110px;
          }
        }
      }
    }
    &.cars-table {
      width: 28%;
      min-width: 28%;

      .table {
        min-width: 300px;

        &__top {
          &-th {
            &.th-name {
              width: 100%;
              min-width: 190px;
            }
            &.th-applications {
              width: 100px;
              min-width: 100px;
              max-width: 100px;
              display: flex;
              gap: 10px;
              align-items: center;

              .sorted_up {
                transform: rotate(180deg);
              }
            }
          }
        }
        &__td {
          &.td-name {
            width: 100%;
            min-width: 190px;
          }
          &.td-applications {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
