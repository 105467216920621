$balloon-font-size: 10px;
$balloon-status-bg-color: #ef9129;
$balloon-width: 166px;
$balloon-min-height: 77px;

.balloon__body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: $balloon-font-size;

  .balloon__header {
    display: flex;
    justify-content: space-between;
    height: 14px !important;
    min-height: 16px !important;

    &-status {
      background-color: $balloon-status-bg-color;
      font-size: inherit;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      color: #fff;
      width: 108px !important;
      min-height: 14px !important;
    }

    &-reqid {
      flex: 1;
      min-height: inherit !important;
      text-align: right;
      font-weight: 500;
      font-size: inherit;
      line-height: 12px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: auto !important;
    }
  }

  .balloon__content {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f0f0f7;
    padding: 0 0 8px 0 !important;
    min-height: fit-content;
    font-size: inherit;
    line-height: 12px;

    &-client {
      min-height: fit-content !important;
      padding: 0 !important;
      font-size: inherit;
      font-weight: 700;
      line-height: inherit;
    }
    &-address {
      min-height: fit-content !important;
      padding: 0 !important;
      font-size: inherit;
      font-weight: 400;
      line-height: inherit;
    }
  }

  .balloon__footer {
    min-height: 12px;

    &-container-id {
      font-size: inherit;
      font-weight: 400;
      line-height: 12px;
      min-height: fit-content !important;

      span {
        font-weight: 700;
      }
    }
  }
}

[class*='balloon'] {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 2px;
  border: 0;
  min-height: $balloon-min-height;
  width: $balloon-width !important;
  padding: 0 !important;
}

[class*='balloon__content'] {
  padding: 5px 10px 10px 5px !important;
  margin: 0 !important;
  box-sizing: border-box !important;

  ymaps {
    width: auto !important;
    height: inherit !important;
  }
}

[class*='balloon__close'] {
  position: absolute !important;
  min-width: 14px;
  min-height: 14px;
  bottom: 24px;
  right: 5px;
}
[class*='balloon__close-button'] {
  background: url('../../../public/assets/map-icons/X.png') no-repeat !important;
  width: 12px !important;
  height: 12px !important;
  opacity: 0.6 !important;
}

[class*='balloon__close-button']:hover {
  opacity: 1 !important;
}

[class*='balloon__tail'] {
  display: none !important;
}
