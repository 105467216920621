@font-face {
  font-family: 'Onest';
  src: url("./../../fonts/Onest-Thin.woff2") format("woff2") url("./../../fonts/Onest-Thin.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url("./../../fonts/Onest-Light.woff2") format("woff2") url("./../../fonts/Onest-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url("./../../fonts/Onest-Regular.woff2") format("woff2") url("./../../fonts/Onest-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url("./../../fonts/Onest-Medium.woff2") format("woff2") url("./../../fonts/Onest-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url("./../../fonts/Onest-Bold.woff2") format("woff2") url("./../../fonts/Onest-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url("./../../fonts/Onest-ExtraBold.woff2") format("woff2") url("./../../fonts/Onest-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Onest';
  src: url("./../../fonts/Onest-Black.woff2") format("woff2") url("./../../fonts/Onest-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
