.login-page {
    background-color: #f6f6f9;
    left: 0;
    top: 0;
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: 1000;
    overflow: hidden;
    overflow-y: auto;

    &__form {
        width: 360px;
        background-color: #FFF;
        padding: 45px 50px 20px 50px;
        min-height: 100vh;

        &-logo {
            width: 130px;
            margin-left: auto;
            margin-bottom: 30px;
        }
        &-title {
            display: block;
            width: 100%;
            font-weight: 700;
            font-size: 30px;
            line-height: 110%;
            color: #13171D;
        }
        &-text {
            display: block;
            width: 100%;
            font-weight: 400;
            font-size: 12px;
            line-height: 120%;
            color: #a0a0a0;
            margin-bottom: 25px;
        }
        &-button {
            height: 38px;
            padding: 0 25px;
            border-radius: 19px;
            background-color: #44A77D;
            border: none;
            cursor: pointer;
            color: #FFF;
            font-size: 12px;
            font-weight: 600;

            &:hover {
                background-color: #4FB78B;
            }
        }
    }
    &__fields {
        width: 100%;
        margin-bottom: 20px;
    }
    &__field {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        &-input {
            width: 100%;
            height: 44px;
            border-radius: 10px;
            border: 1px solid #F0F0F7;
            background-color: #FFF;
            color: #13171D;
            font-size: 12px;
            font-weight: 400;
            line-height: 120%;
            padding: 16px 40px 0 10px;

            &:hover {
                border-color: #DCDEEB;
            }
            &:focus {
                border-color: #DCDEEB;
            }
            &:not(:placeholder-shown) {
                border-color: #DCDEEB;

                & + .login-page__field-label {
                    color: #B2B3C3;
                }
            }
            &.is-error {
                border-color: #FE3434;
            }
        }
        &-label {
            position: absolute;
            top: 7px;
            left: 0;
            width: 100%;
            padding: 0 10px;
            color: #13171D;
            font-size: 10px;
            font-weight: 400;
            line-height: 120%;
        }
        &-icon {
            position: absolute;
            right: 10px;
            font-size: 20px;
            color: #44A77D;
        }
    }
}
