.car-page {
  width: 100%;

  .page-top {
    &__block {
      color: #fff;
      font-size: 10px;
      font-weight: 400;
      line-height: 120%;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
      &-icon {
        width: 24px;
        min-width: 24px;
        height: 24px;
        background-color: #313133;
        margin-right: 8px;

        span {
          font-size: 14px;
          color: #fff;
        }
      }
      b {
        font-weight: 700;
      }
    }
  }
  &__top {
    width: 100%;
    padding: 10px 25px 20px 25px;

    &-item {
      margin-right: 20px;
      color: #b2b3c3;
      font-size: 12px;
      font-weight: 600;
      line-height: 120%;
      cursor: pointer;

      &--delete {
        &:hover {
          color: #fe3434;

          span {
            color: #fe3434;
          }
        }
      }
      &:hover {
        color: #44a77d;

        span {
          color: #44a77d;
        }
      }
      &:first-child {
        color: #44a77d;

        span {
          color: #44a77d;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      span {
        font-size: 20px;
        color: #b2b3c3;
        margin-right: 6px;
      }
    }
  }
  &__content {
    width: calc(100% - 350px);
    border-radius: 14px;
    background: #fff;
    padding: 0 20px 15px 20px;
  }
  &__history {
    width: 340px;
    margin-left: 10px;
    border-radius: 14px;
    background: #fff;
    padding: 0 0 15px 0;

    &-tab {
      width: 50%;
      height: 44px;
      border-bottom: 1px solid #f0f0f7;
      background-color: #fff;
      color: #232323;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;

      &:first-child {
        border-radius: 14px 0 0 0;
      }
      &:last-child {
        border-radius: 0 14px 0 0;
      }
      &.is-active {
        background-color: #232323;
        border-bottom: 1px solid #232323;
        color: #fff;
        cursor: default;
      }
    }
    &-content {
      width: 100%;
      padding: 0 20px;
      .tab-content {
        width: 100%;
      }
      .history-list {
        width: 100%;
        border-top: 1px solid #f0f0f7;
        border-bottom: 1px solid #f0f0f7;
        overflow: hidden;
        overflow-y: auto;
        max-height: 202.6px;

        .history-item {
          width: 100%;
          padding: 10px 0;
          border-bottom: 1px solid #f0f0f7;

          &:last-child {
            border-bottom: none;
          }
          &__column {
            width: 100%;

            &:last-child {
              margin-left: 10px;
              align-items: flex-end;
            }
            &-text {
              display: inline-block;
              white-space: nowrap;
              color: #b2b3c3;
              font-size: 10px;
              font-weight: 400;
              line-height: 120%;
              margin-bottom: 3px;
            }
            &-title {
              display: inline-block;
              color: #232323;
              font-size: 12px;
              font-weight: 700;
              line-height: 120%;
            }
            &-driver {
              margin-top: 2px;
              color: #232323;
              font-size: 10px;
              font-weight: 600;
              line-height: 120%;

              span {
                font-size: 12px;
                color: #232323;
                margin-left: 4px;
              }
            }
          }
        }
      }
    }
    &-download {
      margin-top: 12px;
      color: #b2b3c3;
      font-size: 12px;
      font-weight: 600;
      line-height: 120%;
      cursor: pointer;

      &:hover {
        color: #44a77d;

        span {
          color: #44a77d;
        }
      }
      span {
        font-size: 20px;
        margin-right: 6px;
      }
    }
  }
  &__table {
    width: 100%;
    border-radius: 14px;
    background: #fff;
    padding: 0 20px 15px 20px;
    margin-top: 10px;

    .table {
      min-width: 1200px;

      &__top {
        &-th {
          &.th-sent {
            width: 140px;
            min-width: 140px;
            max-width: 140px;
          }
          &.th-status {
            width: 80px;
            min-width: 80px;
            max-width: 80px;
          }
          &.th-reason {
            width: 100%;
            min-width: 200px;
          }
          &.th-exit {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
          }
          &.th-responsible {
            width: 240px;
            min-width: 240px;
            max-width: 240px;
          }
          &.th-driver {
            width: 280px;
            min-width: 280px;
            max-width: 280px;
          }
        }
      }
      &__td {
        &.td-sent {
          width: 140px;
          min-width: 140px;
          max-width: 140px;
        }
        &.td-status {
          width: 80px;
          min-width: 80px;
          max-width: 80px;

          .td-status__block {
            height: 24px;
            max-width: 100%;
            padding: 0 10px;
            border-radius: 4px;
            color: #fff;
            white-space: nowrap;
            font-size: 10px;
            font-weight: 600;

            &.completed {
              background-color: #4687e8;
            }
            &.repair {
              background-color: #ef9129;
            }
          }
        }
        &.td-reason {
          width: 100%;
          min-width: 200px;
        }
        &.td-exit {
          width: 100px;
          min-width: 100px;
          max-width: 100px;
        }
        &.td-responsible {
          width: 240px;
          min-width: 240px;
          max-width: 240px;
        }
        &.td-driver {
          width: 280px;
          min-width: 280px;
          max-width: 280px;

          .table__td-inner {
            padding-right: 40px;
          }
        }
      }
    }
  }
}
