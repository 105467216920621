.driver-page {
    width: 100%;

    .page-top {

        &__block {
            color: #FFF;
            font-size: 10px;
            font-weight: 400;
            line-height: 120%;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
            &-icon {
                width: 24px;
                min-width: 24px;
                height: 24px;
                background-color: #313133;
                margin-right: 8px;

                span {
                    font-size: 14px;
                    color: #FFF;
                }
            }
            b {
                font-weight: 700;
            }
        }
    }
    &__content {
        width: calc(100% - 350px);
        border-radius: 14px;
        background: #FFF;
        padding: 0 20px 15px 20px;
    }
    &__schedule {
        width: 340px;
        margin-left: 10px;
        border-radius: 14px;
        background: #FFF;
        padding: 0 20px 15px 20px;

        &-calndar {
            width: 100%;
            margin-bottom: 20px;
        }
        .details-list {
            width: 100%;

            &__item {
                width: calc(50% - 10px);
                min-width: calc(50% - 10px);
                padding: 0 30px 4px 0;
                position: relative;
                margin-top: 20px;
                border-bottom: 1px solid #F0F0F7;

                &-title {
                    color: #232323;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 120%;
                    margin-bottom: 3px;
                }
                &-value {
                    color: #232323;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 120%;
                }
                &-icon {
                    position: absolute;
                    right: 0;
                    font-size: 24px;
                    color: #B2B3C3;
                }
            }
        }
    }
    &__table {
        width: 100%;
        border-radius: 14px;
        background: #FFF;
        padding: 0 20px 15px 20px;
        margin-top: 10px;

        .table {
            min-width: 1200px;

            &__top {

                &-th {

                    &.th-id {
                        width: 100px;
                        min-width: 100px;
                        max-width: 100px;
                    }
                    &.th-status {
                        width: 80px;
                        min-width: 80px;
                        max-width: 80px;
                    }
                    &.th-payment {
                        width: 80px;
                        min-width: 80px;
                        max-width: 80px;
                    }
                    &.th-client {
                        width: 160px;
                        min-width: 160px;
                        max-width: 160px;
                    }
                    &.th-address {
                        width: 100%;
                        min-width: 200px;
                    }
                    &.th-phone {
                        width: 120px;
                        min-width: 120px;
                        max-width: 120px;
                    }
                    &.th-driver {
                        width: 180px;
                        min-width: 180px;
                        max-width: 180px;
                    }
                    &.th-car {
                        width: 100px;
                        min-width: 100px;
                        max-width: 100px;
                    }
                    &.th-date {
                        width: 100px;
                        min-width: 100px;
                        max-width: 100px;
                    }
                    &.th-type {
                        width: 160px;
                        min-width: 160px;
                        max-width: 160px;
                    }
                }
            }
            &__td {

                &.td-id {
                    width: 100px;
                    min-width: 100px;
                    max-width: 100px;
                }
                &.td-status {
                    width: 80px;
                    min-width: 80px;
                    max-width: 80px;

                    .td-status__block {
                        height: 24px;
                        max-width: 100%;
                        padding: 0 10px;
                        border-radius: 4px;
                        color: #FFF;
                        white-space: nowrap;
                        font-size: 10px;
                        font-weight: 600;

                        &.completed {
                            background-color: #4687E8;
                        }
                        &.in-work {
                            background-color: #EF9129;
                        }
                    }
                }
                &.td-payment {
                    width: 80px;
                    min-width: 80px;
                    max-width: 80px;

                    .td-payment__block {
                        height: 24px;
                        max-width: 100%;
                        padding: 0 10px;
                        border-radius: 4px;
                        color: #232323;
                        white-space: nowrap;
                        font-size: 10px;
                        font-weight: 600;

                        &.unpaid {
                            border: 1px solid #F15757;
                        }
                        &.paid {
                            border: 1px solid #44A77D;
                        }
                    }
                }
                &.td-client {
                    width: 160px;
                    min-width: 160px;
                    max-width: 160px;
                    font-weight: 600;
                }
                &.td-address {
                    width: 100%;
                    min-width: 200px;
                }
                &.td-phone {
                    width: 120px;
                    min-width: 120px;
                    max-width: 120px;

                    a {
                        color: #232323;
                    }
                }
                &.td-driver {
                    width: 180px;
                    min-width: 180px;
                    max-width: 180px;
                }
                &.td-car {
                    width: 100px;
                    min-width: 100px;
                    max-width: 100px;
                }
                &.td-date {
                    width: 100px;
                    min-width: 100px;
                    max-width: 100px;
                }
                &.td-type {
                    width: 160px;
                    min-width: 160px;
                    max-width: 160px;

                    .table__td-inner {
                        padding-right: 40px;
                    }
                }
            }
        }
    }
}
