.drivers-page {
  width: 100%;

  &__top {
    width: 100%;
    border-bottom: 1px solid #f0f0f7;
    margin-bottom: 24px;
  }
  &__filter {
    margin-right: 10px;
    overflow: hidden;
    overflow-x: auto;

    &-item {
      height: 44px;
      padding: 0 20px;
      color: #232323;
      font-size: 14px;
      font-weight: 400;
      border-right: 1px solid #f0f0f7;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        color: #44a77d;
      }
      &:first-child {
        border-radius: 14px 0 0 0;
      }
      &.is-active {
        background-color: #232323;
        color: #fff;
        cursor: default;
      }
    }
  }
  &__actions {
    margin-left: auto;
    margin-right: 10px;
  }
  &__table {
    width: 100%;
    padding: 0 20px 20px 20px;
    overflow: hidden;
    overflow-x: auto;

    .table {
      min-width: 1200px;

      &__top {
        &-th {
          &.th-id {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
          }
          &.th-status {
            width: 80px;
            min-width: 80px;
            max-width: 80px;
          }
          &.th-name {
            width: 100%;
            min-width: 200px;
          }
          &.th-phone {
            width: 120px;
            min-width: 120px;
            max-width: 120px;
          }
          &.th-telegram {
            width: 120px;
            min-width: 120px;
            max-width: 120px;
          }
          &.th-car {
            width: 280px;
            min-width: 280px;
            max-width: 280px;
          }
          &.th-complete {
            width: 110px;
            min-width: 110px;
            max-width: 110px;
          }
          &.th-on_line {
            width: 110px;
            min-width: 110px;
            max-width: 110px;
          }
          &.th-secret {
            width: 110px;
            min-width: 110px;
            max-width: 110px;
          }
        }
      }
      &__td {
        &.td-id {
          width: 100px;
          min-width: 100px;
          max-width: 100px;
        }
        &.td-status {
          width: 80px;
          min-width: 80px;
          //   max-width: 80px;

          .td-status__block {
            height: 24px;
            max-width: 100%;
            padding: 0 10px;
            border-radius: 4px;
            color: #fff;
            white-space: nowrap;
            font-size: 10px;
            font-weight: 600;

            &.is-ready {
              background-color: #44a77d;
            }
            &.is-sick {
              background-color: #ef9129;
            }
            &.is-time-off {
              background-color: #55f;
            }
            &.is-weekend {
              background-color: #ff0;
              color: #000;
            }
          }
        }
        &.td-name {
          width: 100%;
          min-width: 200px;
          font-weight: 600;
        }
        &.td-phone {
          width: 120px;
          min-width: 120px;
          max-width: 120px;
        }
        &.td-telegram {
          width: 120px;
          min-width: 120px;
          max-width: 120px;

          .td-telegram__block {
            color: #4b90e0;

            span {
              font-size: 12px;
              color: #4b90e0;
              margin-right: 4px;
            }
          }
        }
        &.td-car {
          width: 280px;
          min-width: 280px;
          max-width: 280px;
          font-weight: 600;
        }
        &.td-complete {
          width: 110px;
          min-width: 110px;
          max-width: 110px;
        }
        &.td-on_line {
          width: 110px;
          min-width: 110px;
          max-width: 110px;
        }
        &.td-secret {
          width: 110px;
          min-width: 110px;
          max-width: 110px;

          .table__td-inner {
            padding-right: 40px;

            a {
              color: #4b90e0;
            }
          }
        }
      }
    }
  }
  .schedule-table {
    .table {
      width: 100%;
      border-spacing: 0;

      &__top {
        &-th {
          padding: 0 2px;

          &:first-child {
            padding-left: 10px;
          }
          &:last-child {
            padding-right: 10px;
          }
        }
      }
      &__row {
        border-bottom: 1px solid #f0f0f7;

        &:hover {
          background-color: #232323;
          border-bottom: 1px solid transparent;

          .table__td-inner {
            color: #fff;
          }
        }
      }
      &__td {
        padding: 4px 2px;
        height: 1px;
        min-height: 28px;
        color: #232323;
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;
        overflow: hidden;

        &:first-child {
          padding-left: 0;
          border-radius: 10px 0 0 10px;

          .table__td-inner {
            padding-left: 10px;
          }
        }
        &:last-child {
          padding-right: 0;
          border-radius: 0 10px 10px 0;

          .table__td-inner {
            padding-right: 10px;
            border-radius: 0 10px 10px 0;
          }
        }
        &-inner {
          padding: 0;
          position: relative;
          overflow: hidden;
          border: none;
          margin-bottom: -1px;
        }
      }
    }
    .table {
      min-width: 1200px;

      &__top {
        &-tr {
          border-radius: 16px 16px 0 0;

          &.tr-days_of_week {
            border-radius: 0 0 16px 16px;
          }
        }
        &-th {
          &.th-name {
            width: 100%;
            min-width: 200px;
          }
          &.th-picker {
            min-width: 30px;
            text-align: center;
          }
          &.th-week {
            font-size: 10px;
            width: 30px;
            max-width: 30px;
            min-width: 30px;
            text-align: center;
          }
          &.th-date {
            border: 1px solid;
            border-radius: 16px;
            font-size: 12px;
            width: 28px;
            max-width: 30px;
            min-width: 28px;
            text-align: center;
          }
          &.th-hours {
            padding: 0 10px;
            width: 60px;
            max-width: 60px;
            min-width: 60px;
          }
          &.th-shift {
            padding: 0 10px;
            width: 60px;
            max-width: 60px;
            min-width: 60px;
          }
        }
      }
      &__td {
        &.td-name {
          width: 100%;
          min-width: 200px;
          font-weight: 600;
        }
        &.td-date {
          width: 30px;
          max-width: 30px;
          min-width: 30px;

          .table__td-inner {
            height: 28px;
            width: 28px;
            cursor: pointer;

            select {
              border: 1px solid #f0f0f7;
              height: 100%;
              width: 100%;
              text-align: center;
              -webkit-appearance: none;
              -o-appearance: none;
              -moz-appearance: none;
              appearance: none;
              border-radius: 6px;

              &::-ms-expand {
                display: none;
              }
              &:hover {
                background-color: #4687e8;
                color: #fff;
                border: 1px solid #4687e8;
              }
              &.is-sick {
                background-color: red !important;
                border: 1px solid red !important;
                color: #fff;
              }
              &.is-ready {
                background-color: #aaffdb;
                border: 1px solid #aaffdb;
                color: #000;
              }
              &.is-weekend {
                background-color: #f6f6f9;
                border: 1px solid #f6f6f9;
                color: #000;
              }
              &.is-time-off {
                background-color: #232323;
                border: 1px solid #232323;
                color: #fff;
              }
              &.is-vertical-colored {
                border: 1px solid #232323;
              }
            }
          }
        }
        &.td-hours {
          padding: 0 10px;
          width: 60px;
          max-width: 60px;
          min-width: 60px;
          font-weight: 600;
        }
        &.td-shift {
          padding: 0 10px;
          width: 60px;
          max-width: 60px;
          min-width: 60px;
          font-weight: 600;
        }
      }
    }
  }
}
