.react-datepicker {
    border-radius: 10px;
    border: 1px solid #DCDEEB;
    overflow: hidden;

    &__header {
        background-color: transparent;
        border-bottom: none;
        padding: 4px;
    }
    &__current {

        &-month {
            line-height: 22px;
            border-radius: 12px;
            background: #F6F6F9;
            color: #13171D;
            font-size: 10px;
            font-weight: 600;
        }
    }
    &__navigation {
        height: 22px;
        width: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 4px;

        &-icon {

            &:before {
                border-color: #44A77D !important;
                border-style: solid;
                border-width: 2px 2px 0 0;
                height: 8px;
                top: 8px;
                width: 8px;
            }
            &--previous {
                left: 6px;
            }
            &--next {
                right: 6px;
            }
        }
    }
    &__header {

        .react-datepicker {

            &__day,
            &__day-name,
            &__time-name {
                color: #B2B3C3;
                font-size: 10px;
                font-weight: 400;
                line-height: 120%;
            }
            &__day-names {
                margin-top: 10px;
            }
        }
    }
    &__month--selecting-range {

        .react-datepicker {

            &__day {

                &--in-range {
                    color: #13171D !important;
                }
                &--selected {
                    color: #FFF !important;
                }
            }
        }
    }
    &__week {
        display: flex;
    }
    &__day {
        color: #13171D;
        font-size: 10px;
        font-weight: 600;
        line-height: 120%;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50% !important;

        &--selected {
            background-color: #232323 !important;
            color: #FFF !important;
        }
        &--in-range {
            color: #FFF !important;
        }
    }
}
.react-datepicker-popper[data-placement^=bottom] {

    .react-datepicker__triangle {
        fill: #DCDEEB;
        color: #DCDEEB;
    }
}
.form-field {

    &__calendar {

        .css-1bn53lx {
            border-radius: 10px;
            padding-right: 0;
            height: 44px;

            .css-1uvydh2 {
                height: 100%;
                padding: 0 0 0 10px;
                color: #13171D;
                font-family: Onest, sans-serif !important;
                font-size: 12px;
                font-weight: 400;
                line-height: 120%;
            }
        }
        .css-slyssw {
            position: relative;
            margin-right: 10px;
            padding: 10px;

            .css-w0pj6f {
                position: absolute;
                font-family: 'clean-world' !important;
                font-size: 16px;
                color: #44A77D;

                &:before {
                    content: "\e90f";
                }
            }
            .css-vubbuv {
                display: none !important;
            }
        }
    }
}
.MuiPaper-root {
    margin-bottom: 20px;

    .MuiYearCalendar-root {
        width: 200px;
        max-height: 216px;

        .MuiPickersYear-root {
            flex-basis: 33%;

            .MuiPickersYear-yearButton {
                color: #13171D;
                font-family: 'clean-world' !important;
                font-weight: 500;
                margin: 2px 0;
                height: 30px;
                width: 56px;
                border-radius: 15px;

                &.Mui-selected {
                    background-color: #232323;
                    color: #FFF;
                }
            }
        }
    }
    .MuiDateCalendar-root {
        height: 216px;
        margin: 0 auto;
        max-height: 216px;
        width: 200px;

        .MuiPickersCalendarHeader-root {
            margin: 4px 4px 10px 4px !important;
            padding-left : 6px !important;
            padding-right: 6px !important;
            max-height: 22px !important;
            min-height: 22px !important;
            position: relative;
            border-radius: 12px;
            background-color: #F6F6F9;

            .MuiDateCalendar-root {
                height: auto;

                .MuiPickersSlideTransition-root {
                    min-height: 200px;
                }
            }
            .MuiPickersCalendarHeader-labelContainer {
                margin-left: auto;
                z-index: 10;
                color: #13171D;
                font-family: Onest, sans-serif !important;
                font-size: 10px;
                font-weight: 600;
                line-height: 120%;
            }
            .MuiPickersArrowSwitcher-root {
                position: absolute;
                width: 100%;
                left: 0;
                justify-content: space-between;
            }
            .MuiPickersArrowSwitcher-button {
                padding: 0;
                width: 22px;
                min-width: 22px;
                height: 22px;

                &:hover {
                    background-color: #F6F6F9;
                }
                .MuiSvgIcon-root {
                    fill: #44A77D;
                }
            }
        }
        .MuiDayCalendar-header {

            .MuiDayCalendar-weekDayLabel {
                width: 24px;
                height: 24px;
                margin: 2px;
                color: #B2B3C3;
                font-family: Onest, sans-serif !important;
                font-size: 10px;
                font-weight: 400;
            }
        }
        .MuiDayCalendar-monthContainer {

            .MuiPickersDay-dayWithMargin {
                width: 24px;
                height: 24px;
                margin: 2px;
                color: #13171D;
                font-family: Onest, sans-serif !important;
                font-size: 10px;
                font-weight: 600;

                &.Mui-selected {
                    background-color: #232323;
                    color: #FFF;
                }
            }
        }
    }
}

