.cars-page {
    width: 100%;

    &__top {
        width: 100%;
        border-bottom: 1px solid #F0F0F7;
        margin-bottom: 24px;
    }
    &__filter {
        margin-right: 10px;
        overflow: hidden;
        overflow-x: auto;

        &-item {
            height: 44px;
            padding: 0 20px;
            color: #232323;
            font-size: 14px;
            font-weight: 400;
            border-right: 1px solid #F0F0F7;
            white-space: nowrap;
            cursor: pointer;

            &:hover {
                color: #44A77D;
            }
            &:first-child {
                border-radius: 14px 0 0 0;
            }
            &.is-active {
                background-color: #232323;
                color: #FFF;
                cursor: default;
            }
        }
    }
    &__actions {
        margin-left: auto;
        margin-right: 10px;
    }
    &__table {
        width: 100%;
        padding: 0 20px 20px 20px;
        overflow: hidden;
        overflow-x: auto;

        .table {
            min-width: 1200px;

            &__top {

                &-th {

                    &.th-id {
                        width: 100px;
                        min-width: 100px;
                        max-width: 100px;
                    }
                    &.th-status {
                        width: 80px;
                        min-width: 80px;
                        max-width: 80px;
                    }
                    &.th-name {
                        width: 100%;
                        min-width: 200px;
                    }
                    &.th-driver {
                        width: 250px;
                        min-width: 250px;
                        max-width: 250px;
                    }
                    &.th-phone {
                        width: 120px;
                        min-width: 120px;
                        max-width: 120px;
                    }
                    &.th-sts {
                        width: 100px;
                        min-width: 100px;
                        max-width: 100px;
                    }
                    &.th-pts {
                        width: 100px;
                        min-width: 100px;
                        max-width: 100px;
                    }
                    &.th-owner {
                        width: 160px;
                        min-width: 160px;
                        max-width: 160px;
                    }
                    &.th-added {
                        width: 150px;
                        min-width: 150px;
                        max-width: 150px;
                    }
                }
            }
            &__td {

                &.td-id {
                    width: 100px;
                    min-width: 100px;
                    max-width: 100px;
                }
                &.td-status {
                    width: 80px;
                    min-width: 80px;
                    max-width: 80px;

                    .td-status__block {
                        height: 24px;
                        max-width: 100%;
                        padding: 0 10px;
                        border-radius: 4px;
                        color: #FFF;
                        white-space: nowrap;
                        font-size: 10px;
                        font-weight: 600;

                        &.ready {
                            background-color: #44A77D;
                        }
                        &.in-repair {
                            background-color: #9E1AEF;
                        }
                        &.in-work {
                            background-color: #EF9129;
                        }
                    }
                }
                &.td-name {
                    width: 100%;
                    min-width: 200px;
                    font-weight: 600;
                }
                &.td-driver {
                    width: 250px;
                    min-width: 250px;
                    max-width: 250px;
                }
                &.td-phone {
                    width: 120px;
                    min-width: 120px;
                    max-width: 120px;
                    
                    a {
                        color: #232323;
                    }
                }
                &.td-sts {
                    width: 100px;
                    min-width: 100px;
                    max-width: 100px;
                }
                &.td-pts {
                    width: 100px;
                    min-width: 100px;
                    max-width: 100px;
                }
                &.td-owner {
                    width: 160px;
                    min-width: 160px;
                    max-width: 160px;
                }
                &.td-added {
                    width: 150px;
                    min-width: 150px;
                    max-width: 150px;

                    .table__td-inner {
                        padding-right: 40px;
                    }
                }
            }
        }
    }
}
